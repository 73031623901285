import { createApp, provide, ref } from 'vue';
import VueCookies from 'vue-cookies';
import VueGtagPlugin from 'vue-gtag';
import Toast, { POSITION } from 'vue-toastification';

import useAxiosErrorHandler from '@/composables/useAxiosErrorHandler';
import i18n from '@/i18n';
import store from '@/store';
import { trackEvent } from '@/utilities/socialTracking';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faInstagram, faTiktok, faXTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import {
    faArrowDownToLine,
    faArrowRight,
    faArrowsRotate,
    faBagShopping,
    faBars,
    faChartMixed,
    faCircleCheck,
    faClone,
    faCompactDisc,
    faConveyorBeltArm,
    faDollarSign,
    faEarthEurope,
    faEye,
    faEyeSlash,
    faGripLines,
    faGuitars,
    faHandHoldingDollar,
    faHandshake,
    faHeart,
    faCircleExclamation as falCircleExclamation,
    faCloudArrowUp as falCloudArrowUp,
    faLeaf,
    faPlus as falPLus,
    faMagnifyingGlass,
    faPlay,
    faRocketLaunch,
    faSquareExclamation,
    faTruck,
    faTruckClock,
    faTruckFast,
    faWaveformLines,
} from '@fortawesome/pro-light-svg-icons';
import { faBoxesStacked, faClock } from '@fortawesome/pro-regular-svg-icons';
import {
    faAngleLeft,
    faAngleRight,
    faBackwardStep,
    faCaretDown,
    faChartLine,
    faCheck,
    faChevronDown,
    faCircleDollar,
    faCircleDot,
    faCircleInfo,
    faCirclePause,
    faCirclePlay,
    faCircleXmark,
    faCloudArrowDown,
    faCloudArrowUp,
    faCoins,
    faDown,
    faForwardStep,
    faImage,
    faListMusic,
    faPlay as falPlay,
    faPause,
    faPlus,
    faRecordVinyl,
    faBars as fasBars,
    faCircleCheck as fasCircleCheck,
    faTrash,
    faTriangleExclamation,
    faUp,
    faUpload,
    faWallet,
    faXmark,
} from '@fortawesome/pro-solid-svg-icons';
import { faCircleExclamation as fasrCircleExclamation } from '@fortawesome/sharp-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import VCalendar from 'v-calendar';

import FooterSection from '@/components/global/FooterSection.vue';
import Checkbox from '@/components/global/forms/Checkbox.vue';
import Password from '@/components/global/forms/Password.vue';
import HeaderMessage from '@/components/global/HeaderMessage.vue';
import Navbar from '@/components/global/Navbar.vue';
import ProgressBar from '@/components/global/ProgressBar.vue';
import ShippingCountryReminder from '@/components/global/ShippingReminderHeader.vue';
import Trigger from '@/components/global/Trigger.vue';
import Websockets from '@/components/global/Websockets.vue';

import 'vue-toastification/dist/index.css';
// Bootstrap the application
import '@/bootstrap';
import 'v-calendar/style.css';

import { logFeatureToggles } from '@/composables/useFeatureFlag';
import { getGtagConfig } from '@/ga';

library.add(
    faTiktok,
    faClock,
    faArrowDownToLine,
    faWallet,
    faCoins,
    faRecordVinyl,
    faCircleDollar,
    faChartLine,
    faAngleLeft,
    faAngleRight,
    faArrowRight,
    faArrowsRotate,
    faBackwardStep,
    faBagShopping,
    faBars,
    fasBars,
    faCaretDown,
    faEarthEurope,
    faCompactDisc,
    faCheck,
    fasrCircleExclamation,
    faImage,
    faListMusic,
    faChevronDown,
    faCircleCheck,
    faCircleDot,
    falCircleExclamation,
    faCircleInfo,
    faCirclePause,
    faCirclePlay,
    faCircleXmark,
    faClone,
    faCloudArrowDown,
    faCloudArrowUp,
    faHeart,
    faWaveformLines,
    faTruckClock,
    faDown,
    faEye,
    falCloudArrowUp,
    faEyeSlash,
    faForwardStep,
    faGripLines,
    faGuitars,
    faInstagram,
    falPlay,
    faMagnifyingGlass,
    faPause,
    faPlay,
    faPlus,
    faRocketLaunch,
    faTrash,
    faTruckFast,
    faTriangleExclamation,
    faTruck,
    faXTwitter,
    faSquareExclamation,
    faXmark,
    faYoutube,
    faUp,
    faUpload,
    fasCircleCheck,
    falPLus,
    faConveyorBeltArm,
    faHandHoldingDollar,
    faHandshake,
    faLeaf,
    faChartMixed,
    faDollarSign,
    faBoxesStacked,
);

const app = createApp({
    setup() {
        const loaded = ref(false);
        provide('audio', new Audio());

        const initialize = async () => {
            if (window.location.pathname.includes('/email/verify')) {
                trackEvent('CompleteRegistration', ['meta', 'tiktok'], {});
            }

            if (window.Elasticstage && window.Elasticstage.user_uuid) {
                try {
                    await store.dispatch('user/setUserUuid', window.Elasticstage.user_uuid);
                    await store.dispatch('user/fetch', window.Elasticstage.user_uuid);
                } catch (error) {
                    useAxiosErrorHandler(error, { notify: false });
                }

                loaded.value = true;
            }

            // PLAT-2038 - workaround for known Instagram bug
            window._AutofillCallbackHandler = window._AutofillCallbackHandler || function () {};

            try {
                await store.dispatch('cart/fetch');
            } catch (error) {
                useAxiosErrorHandler(error, { notify: false });
            }
        };

        initialize();

        return { loaded };
    },
});

app.component('HeaderMessage', HeaderMessage);
app.component('Navbar', Navbar);
app.component('Websockets', Websockets);
app.component('Password', Password);
app.component('FooterSection', FooterSection);
app.component('Trigger', Trigger);
app.component('FontAwesomeIcon', FontAwesomeIcon);
app.component('ProgressBar', ProgressBar);
app.component('ShippingCountryReminder', ShippingCountryReminder);
app.component('Checkbox', Checkbox);

app.use(store);
app.use(i18n);
app.use(Toast, {
    position: POSITION.TOP_CENTER,
});
app.use(VCalendar);
app.use(VueCookies);

// Google Analytics
getGtagConfig()
    .then((gtagConfig) => {
        if (gtagConfig.enabled) {
            app.use(VueGtagPlugin, gtagConfig);
        }
    })
    .catch((error) => console.error('Analytics setup failed:', error));

const releaseId = import.meta.env.VITE_RELEASE_ID;
const releaseName = import.meta.env.VITE_RELEASE_NAME;

window.addEventListener('newrelic', (evt) => {
    if (evt.detail.loaded) {
        window.newrelic.setApplicationVersion(releaseId);
        window.newrelic.addRelease(releaseName, releaseId);
    }
});

// Custom error handler
app.config.errorHandler = (err, vm, info) => {
    const { __name: componentName = 'anonymous component', __file: componentPath } = vm.$options;

    const errorContext = {
        componentName,
        componentPath,
        propsData: vm.$props,
    };

    if (typeof window.newrelic !== 'undefined' && window.newrelic && window.newrelic.noticeError) {
        window.newrelic.noticeError(err, errorContext);
    }

    console.error(`Error in ${componentPath}: ${info}`, err);
};

const components = import.meta.glob('/resources/js/**/*.vue');

// Dynamic component registration
const loadComponent = async (path) => {
    try {
        const componentImport = components[`/resources/js/${path}.vue`];
        if (!componentImport) throw new Error(`Component not found: ${`/resources/js/${path}.vue`}`);
        const module = await componentImport();
        return module.default;
    } catch (error) {
        console.error(`Error loading view component from template ${path}:`, error);
        return null;
    }
};

const registerComponent = (app, name, component) => {
    if (component) {
        app.component(name, component);
    }
};

const initApp = async (app) => {
    const paths = document.getElementById('component')?.getAttribute('data-component')?.split(',');

    if (paths && paths.length > 0) {
        for (const path of paths) {
            const component = await loadComponent(path);

            if (component) {
                registerComponent(app, component.__name, component);
            }
        }
    } else {
        console.warn('No components found to load');
    }

    app.mount('#app');
};

initApp(app);

logFeatureToggles();
