<template>
    <div class="flex items-center rounded-lg px-2 bg-gray-750">
        <button
            class="w-[32px] h-[32px] rounded text-xl"
            v-bind="bindTestData('quantity.decrement')"
            @click="decrement"
        >
            -
        </button>

        <div class="w-[40px] text-center" v-bind="bindTestData('quantity.value')">
            {{ value }}
        </div>

        <button
            class="w-[32px] h-[32px] rounded text-xl"
            v-bind="bindTestData('quantity.increment')"
            @click="increment"
        >
            +
        </button>
    </div>
</template>

<script setup lang="ts">
    import { defineModel, ModelRef } from 'vue';

    import { bindTestData } from '@/tests/utilities';

    const value: ModelRef<number> = defineModel({ required: true });

    const emit = defineEmits(['maxReached']);

    const props = defineProps({
        min: { type: Number, default: 0 },
        max: { type: Number, default: Infinity },
        disabled: { type: Boolean, default: false },
    });

    const increment = () => {
        if (value.value >= props.max) {
            emit('maxReached');

            return;
        }

        if (!props.disabled) {
            value.value++;
        }
    };

    const decrement = () => {
        if (!props.disabled && value.value > 0 && value.value > props.min) {
            value.value--;
        }
    };
</script>

<style scoped>
    .no-arrows::-webkit-inner-spin-button,
    .no-arrows::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .no-arrows {
        -moz-appearance: textfield;
        appearance: textfield;
    }
</style>
