<template>
    <div>
        <div class="flex items-center justify-center py-2.5 cursor-pointer space-x-8 z-50" @click="open">
            <div class="flex items-center justify-center link" :title="t('Shipping country and language')">
                <font-awesome-icon v-if="!props.giftStyling" icon="fa-light fa-truck" class="text-2xl mr-1" />
                <font-awesome-icon v-if="!props.giftStyling" icon="fa-light fa-arrow-right" class="text-lg mr-1" />

                <div class="w-[20px] h-[20px] rounded-full" :class="{ 'bg-gray-700': !currentCountry }">
                    <Flag
                        :country="currentCountry?.iso"
                        :rounded="true"
                        class="m-0"
                        background="/images/vendor/vue-country-flag/dist/flags.png"
                    />
                </div>
            </div>
        </div>

        <Transition>
            <section
                v-if="isOpen"
                class="text-left fixed min-h-screen z-50 inset-0 overflow-y-auto scrollbar-none"
                aria-labelledby="modal-title"
                role="dialog"
                aria-modal="true"
            >
                <div
                    class="fixed inset-0 bg-black/60"
                    :class="{ 'backdrop-blur-sm': !props.giftStyling }"
                    aria-hidden="true"
                    @click.stop="close"
                />
                <div class="sm:mt-20" @click.stop>
                    <div class="relative panel" :class="{ '!bg-[#f2f2f7] !border-[#f2f2f7]': props.giftStyling }">
                        <font-awesome-icon
                            icon="fa-solid fa-xmark"
                            class="absolute top-4 right-4 text-gray-300 hover:text-gray-200 text-xl cursor-pointer"
                            :class="{ '!text-[#323333]': props.giftStyling }"
                            @click="close"
                        />
                        <p class="font-bold text-xl text-orange mb-6" :class="{ '!text-[#2E4053]': props.giftStyling }">
                            {{ $t('Your Country & Language') }}
                        </p>

                        <div>
                            <CountryDropdown
                                :countries="countries"
                                :selected="currentCountry"
                                :gift-styling="props.giftStyling"
                                class="mb-3"
                                @changed="changeCountry"
                            />

                            <LocaleDropdown
                                :gift-styling="props.giftStyling"
                                :locales="locales"
                                :selected="currentLocale"
                                @changed="changeLocale"
                            />
                        </div>
                    </div>
                </div>
            </section>
        </Transition>
    </div>
</template>

<script setup lang="ts">
    import { computed, ComputedRef, ref, watch } from 'vue';
    import Flag from 'vue-country-flag-next';
    import { useStore } from 'vuex';

    import useAxiosErrorHandler from '@/composables/useAxiosErrorHandler';
    import i18n from '@/i18n';
    import { toPairs } from 'lodash';

    import CountryDropdown from '@/components/global/locales/CountryDropdown.vue';
    import LocaleDropdown from '@/components/global/locales/LocaleDropdown.vue';

    import { Country, Locale } from '@/types/locales';

    const props = defineProps({
        giftStyling: {
            type: Boolean,
            default: false,
        },
    });

    const store = useStore();
    const { t } = i18n.global;

    const defaultLocale: Locale = ['English (British)', 'en_GB'];

    const isBusy = ref(false);
    const isOpen = computed(() => store.getters['locales/isModalOpen']);
    const locales = ref<[string, string][]>([]);
    const currentCountry = ref<Country | null>(null);
    const currentLocale = ref<Locale>(defaultLocale);

    const locale: ComputedRef<string> = computed(() => store.getters['locales/locale']);
    const countries: ComputedRef<Country[]> = computed(() => store.getters['config/shippingCountries']);
    const shippingCountry: ComputedRef<string> = computed(() => store.getters['locales/shippingCountry']);

    watch(
        countries,
        async () => {
            // eslint-disable-next-line no-undef
            locales.value = toPairs(Elasticstage.locales_list);

            try {
                await store.dispatch('config/getShippingCountries');
            } catch (error: any) {
                useAxiosErrorHandler(error, { notify: false });
            }

            currentCountry.value = countries.value.find((country) => country.iso === shippingCountry.value) || null;
            currentLocale.value = locales.value.find((localeItem) => localeItem[1] === locale.value) || defaultLocale;
        },
        { immediate: true },
    );

    const changeLocale = async (value: string) => {
        if (isBusy.value) return;

        isBusy.value = true;

        store.commit('locales/SET_LOCALE', value);

        location.reload();
    };

    const changeCountry = async (iso: string) => {
        if (isBusy.value) return;

        isBusy.value = true;

        store.commit('locales/SET_SHIPPING_COUNTRY', iso);

        location.reload();
    };

    const open = () => {
        store.commit('locales/OPEN_MODAL');
    };

    const close = () => {
        store.commit('locales/CLOSE_MODAL');
    };
</script>

<!--Need these to override Flag plugin styles-->
<style>
    .normal-flag.rounded {
        background-position-x: center !important;
        border-radius: 999px !important;
        display: block !important;
        margin: -10px !important;
        width: 40px !important;
    }
</style>
