import { AxiosResponse } from 'axios';

import { api } from '@/services/ApiService';

import { Genre, GenresListResponse } from '@/types/genres';

export default {
    async fetch(pageUrl?: string): Promise<{ data: Genre[]; links: { next: string | null } }> {
        const url = pageUrl || `/api/active_genres`;
        const response: AxiosResponse<{ data: Genre[]; links: { next: string | null } }> = await api.get(url);
        return response.data;
    },

    async fetchGenresList(): Promise<{ data: GenresListResponse }> {
        const response: AxiosResponse<{ data: any }> = await api.get(`/api/genres_list`);
        return response.data;
    },

    async fetchReleases(genre: string) {
        const response: AxiosResponse<{ data: any }> = await api.get(`/api/genres/${genre}/releases`);
        return response.data.data;
    },

    async fetchReleaseGroups(genre: string, pageUrl?: string) {
        const url = pageUrl || `/api/genres/${genre}/release_groups`;
        const response: AxiosResponse<{ data: any; links?: { next: string | null } }> = await api.get(url);
        return response.data;
    },
};
