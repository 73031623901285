<template>
    <div></div>
</template>

<script setup lang="ts">
    import { computed, onMounted } from 'vue';
    import { useStore } from 'vuex';

    import useAxiosErrorHandler from '@/composables/useAxiosErrorHandler';

    import socket from '@/services/socket';
    import { notify } from '@/functions/useNotification';

    import { WebsocketsInstagramConnectResponse } from '@/types/websockets';

    const store = useStore();

    const uuid = computed(() => store.state.user.uuid);

    onMounted(() => {
        connect();
    });

    const connect = () => {
        socket
            .private(`users.${uuid.value}.notifications`)
            .listen('Message', (message: string) => notify(message))
            .listen('ReleaseCloneStarted', async (e: { source: string; destination: string }) => {
                store.dispatch('releases/updateCloningStatus', { uuid: e.destination, status: true });
                refreshReleases([e.source, e.destination]);
            })
            .listen('ReleaseCloneFinished', async (e: { source: string; destination: string }) => {
                store.dispatch('releases/updateCloningStatus', { uuid: e.destination, status: false });
                refreshReleases([e.source, e.destination]);
            })
            .listen('ArtworkCloneFinished', async (e: { release: string }) => {
                refreshReleases([e.release]);
            })
            .listen('PageInstagramConnected', (e: WebsocketsInstagramConnectResponse) => {
                window.emitter.emit(`instagram-token-received-${e.page_uuid}`);
            })
            // @ts-expect-error - this is a known issue with the package
            .listenToAll((event, data) => {
                console.log(event, data);
            });
    };

    const refreshReleases = async (uuids?: string[]) => {
        try {
            await store.dispatch('releases/fetch');
        } catch (error: any) {
            useAxiosErrorHandler(error, { notify: false });
        }

        if (!uuids || uuids.length === 0) return;

        uuids.forEach(async (uuid) => {
            try {
                await store.dispatch('release/fetch', uuid);
            } catch (error: any) {
                useAxiosErrorHandler(error, { notify: false });
            }
        });
    };
</script>
