<template>
    <div class="flex items-center gap-x-3 mt-1">
        <input
            :id="props.id"
            v-model="value"
            class="checkbox"
            :class="{ '!border-red-500': props.error && !value, '!ring-red-500': props.error && !value }"
            type="checkbox"
            :name="props.id"
            :disabled="props.disabled"
            @change="handleChange"
        />

        <label
            :for="props.id"
            class="text-gray-200"
            :class="{ 'text-xs': variation === 'xs', 'text-sm': variation === 'sm' }"
        >
            <slot name="label"> </slot>
        </label>
    </div>
</template>

<script setup lang="ts">
    import { defineModel, defineProps, ModelRef } from 'vue';

    const value: ModelRef<boolean> = defineModel({ required: true });

    const props = defineProps({
        disabled: { type: Boolean, default: false },
        id: { type: String, required: true },
        error: { type: Boolean, default: false },
        variation: { type: String, default: 'default' },
    });

    const handleChange = (event: Event): void => {
        const input = event.target as HTMLInputElement;
        value.value = input.checked;
    };
</script>
