<template>
    <div class="hidden md:block relative">
        <p class="link link-grey hover:no-underline" @click.prevent="toggle">
            <span
                class="inline-block max-w-[100px] min-w-[40px] truncate align-bottom text-right"
                :data-test="applyTestData('navbar.menu.trigger')"
            >
                {{ user?.first_name }}
            </span>

            <font-awesome-icon icon="fa-solid fa-caret-down" class="align-top ml-1 h-[17.5px]" />
        </p>

        <div v-if="isOpen" class="fixed top-[58px] inset-0 bg-black/60 backdrop-blur-sm z-30" @click.prevent="toggle" />

        <div v-if="isOpen" class="absolute right-0 mt-[8px] w-16 overflow-hidden inline-block z-50">
            <div class="h-11 w-11 bg-gray-800 rotate-45 transform origin-bottom-left" />
        </div>

        <div
            v-if="isOpen"
            class="absolute rounded shadow-modal -right-4 mt-[32px] w-[260px] py-4 z-50 text-lg leading-none grid bg-gray-800 gap-1"
            :data-test="applyTestData('navbar.menu.container')"
        >
            <a
                v-if="user.is_seller"
                href="/es/pages"
                class="rounded px-4 pt-3 pb-2 bg-white bg-opacity-0 hover:bg-opacity-10 transition ease-in-out duration-200 hover:text-white hover:no-underline group"
            >
                <p class="group-hover:text-orange-500 text-white">{{ $t('Pages') }}</p>
                <p class="text-base text-gray-200">
                    {{ $t('Manage your artist pages') }}
                </p>
            </a>

            <a
                v-if="user.is_seller"
                href="/es/releases"
                class="rounded px-4 pt-3 pb-2 bg-white bg-opacity-0 hover:bg-opacity-10 transition ease-in-out duration-200 hover:text-white hover:no-underline group"
            >
                <p class="group-hover:text-orange-500 text-white">{{ $t('Releases') }}</p>
                <p class="text-base text-gray-200">
                    {{ $t('Manage your releases') }}
                </p>
            </a>

            <a
                v-if="user.is_seller"
                href="/es/sales"
                class="rounded px-4 pt-3 pb-2 bg-white bg-opacity-0 hover:bg-opacity-10 transition ease-in-out duration-200 hover:text-white hover:no-underline group"
            >
                <p class="group-hover:text-orange-500 text-white">{{ $t('Sales') }}</p>
                <p class="text-base text-gray-200">
                    {{ $t('Manage your earnings') }}
                </p>
            </a>

            <!-- <a
                href="/es/orders"
                class="rounded px-4 pt-3 pb-2 bg-white bg-opacity-0 hover:bg-opacity-10 transition ease-in-out duration-200 hover:text-white hover:no-underline group"
            >
                <p class="group-hover:text-orange-500 text-white">{{ $t('Orders') }}</p>
                <p class="text-base text-gray-200">
                    {{ $t('View of your orders') }}
                </p>
            </a> -->

            <a
                href="/es/account"
                class="rounded px-4 pt-3 pb-2 bg-white bg-opacity-0 hover:bg-opacity-10 transition ease-in-out duration-200 hover:text-white hover:no-underline group"
            >
                <p class="group-hover:text-orange-500 text-white">{{ $t('Account') }}</p>
                <p class="text-base text-gray-200">
                    {{ $t('Manage your account') }}
                </p>
            </a>

            <a
                v-if="user.is_admin"
                target="_blank"
                href="/es/admin"
                class="rounded px-4 pt-5 pb-4 bg-white bg-opacity-0 hover:bg-opacity-10 transition ease-in-out duration-200 hover:text-white hover:no-underline group"
            >
                <p class="group-hover:text-orange-500 text-white">{{ $t('Admin') }}</p>
            </a>

            <a
                href="#"
                class="rounded px-4 pt-5 pb-4 bg-white bg-opacity-0 hover:bg-opacity-10 transition ease-in-out duration-200 hover:text-white hover:no-underline group"
                @click.prevent="logout"
            >
                <p class="group-hover:text-orange-500 text-white">{{ $t('Logout') }}</p>
            </a>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { onMounted, ref } from 'vue';

    import useAxiosErrorHandler from '@/composables/useAxiosErrorHandler';
    import store from '@/store';

    import { User } from '@/types/user';

    import { applyTestData } from '@/tests/utilities';

    const { user } = defineProps({
        user: {
            type: Object as () => User,
            required: true,
        },
    });

    const isOpen = ref<boolean>(false);

    onMounted(() => {
        window.emitter.on('close-user-modal', () => {
            close();
        });
    });

    const logout: () => void = async () => {
        try {
            await store.dispatch('user/logout');
        } catch (error: any) {
            useAxiosErrorHandler(error, { notify: false });
        }
    };

    const close: () => void = () => {
        isOpen.value = false;
    };

    const toggle: () => void = () => {
        isOpen.value = !isOpen.value;
    };
</script>
