import axios, { AxiosError } from 'axios';

import { AttachEtagToRequest, GetEtagFromResponse } from '@/services/interceptors/Etag.interceptor';
import LoggingInterceptor from '@/services/interceptors/Logging.interceptor';

const Laravel = window.Laravel ?? {};

export const api = (window.axios = axios.create({
    headers: {
        'X-CSRF-TOKEN': Laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'X-CART-ID': localStorage.getItem('cartId'),
    },
    timeout: 20000,
}));

const EtagKeys = {
    '/api/shipping_countries': 'configEtag',
};

api.interceptors.response.use(
    (response) => {
        return response;
    },
    (error: AxiosError) => {
        const route = error.config?.url || 'Unknown route';
        const method = error.config?.method?.toUpperCase() || 'UNKNOWN METHOD';
        const status = error.response?.status || 'Unknown Status';
        const errorMessage =
            error.response?.data?.message || `API call ${method} ${route} failed with status ${status}`;

        if (status === 303) {
            return Promise.reject(error);
        }

        console.error(`[API Error]: ${errorMessage}`, error);

        return Promise.reject(error);
    },
);

if (process.env.NODE_ENV !== 'test') {
    api.interceptors.request.use(
        (config) => {
            const con = AttachEtagToRequest(config, EtagKeys);
            return Promise.resolve(con);
        },
        (error) => {
            return Promise.reject(error);
        },
    );

    api.interceptors.response.use(
        async (response) => {
            GetEtagFromResponse(response, EtagKeys);

            return LoggingInterceptor(response);
        },
        (error) => {
            return Promise.reject(error);
        },
    );
}
