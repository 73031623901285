import { AxiosError } from 'axios';

import { notify } from '@/functions/useNotification';

interface AxiosErrorOptions {
    notify: boolean;
}

const defaultOptions: AxiosErrorOptions = {
    notify: true,
};

export default function useAxiosErrorHandler(error: AxiosError, options: AxiosErrorOptions = defaultOptions) {
    const errorMessage =
        error.response?.data[0] || error.response?.data.message || 'An error occurred. Please try again.';

    if (options.notify) {
        notify(errorMessage, 'ERROR');
    }

    console.error(error);

    return error.response?.data.errors;
}
